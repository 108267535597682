





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Topic from '@improve/common-utils/src/model/Topic';
import StatsData from '@improve/common-utils/src/model/StatsData';
import BaseTopicCard from '@improve/common-components/src/components/widgets/BaseTopicCard.vue';

@Component({
  name: 'TopicsTab',
  components: {
    BaseTopicCard
  }
})
export default class TopicsTab extends Vue {
  @Getter organizationTopics!: Array<Topic>;

  @Getter topicStatsById!: Map<string, StatsData>;

  @Action fetchTopicsByContext!: ActionMethod;

  @Action fetchTopicStatsByBatch!: ActionMethod;

  async created(): Promise<void> {
    await this.fetchTopicsByContext();
    await this.fetchTopicStats();
  }

  fetchTopicStats(): Promise<StatsData> {
    return this.fetchTopicStatsByBatch(this.organizationTopics);
  }

  getTopicStats(topicId: string): StatsData {
    return this.topicStatsById && this.topicStatsById.has(topicId)
      ? this.topicStatsById.get(topicId)!
      : new StatsData();
  }

  openTopicDetails(topic: Topic): void {
    this.$router.push({ name: 'TopicDetails', params: { id: topic!.id! } });
  }
}
